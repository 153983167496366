<template>
  <div class="retrievalsearch">
    <div style="marigin-top: 40px" class="serchdiv">
      <el-input
        placeholder="请输入关键字"
        v-model="input3"
        class="input-with-select"
        style="
          width: 800px;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          border: 1px solid #d9d8d8;
        "
        @keyup.enter.native="entersearch()"
      >
        <el-select
          style="
            width: 120px;
            background-color: #fff;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          "
          v-model="select"
          slot="prepend"
          placeholder="请选择"
        >
          <el-option label="图书" value="图书"></el-option>
          <el-option label="期刊" value="期刊"></el-option>
        </el-select>
        <el-button
          style="
            background-color: #fff;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            background-color: #ae703e;
            color: #fff;
            height: 42px;
          "
          slot="append"
          @click="search()"
          >立即检索</el-button
        >
      </el-input>
    </div>
    <div class="checkradio">
      <el-radio v-model="radio" label="5">全部</el-radio>
      <el-radio v-model="radio" label="0">标题</el-radio>
      <el-radio v-model="radio" label="1">作者</el-radio>
      <el-radio v-model="radio" label="2">出版社</el-radio>
      <el-radio v-model="radio" label="3">ISBN</el-radio>
      <el-radio v-model="radio" label="4">索书号</el-radio>
    </div>
  </div>
</template>
<script>
export default {
  name: "personalcenter",
  data() {
    return {
      input3: "",
      select: "图书",
      radio: "5",
    };
  },
  methods: {
    entersearch() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        this.$router.push({
          path: "/search",
          query: { type: this.select, title: this.input3 },
        });
      }
    },
    search() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        if (window.location.search) {
          this.$router.push({
            path: "/Aggregateretrieval",
          });
        }
        this.$emit("func", this.radio, this.select,this.input3);
      }
    },
  },
  created() {},
};
</script>
<style scope>
.retrievalsearch {
  width: 1200px;
  margin: 20px auto;
  
}
.checkradio {
  width: 650px;
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  padding: 0px 15px;
  border-radius: 10px;
  margin-top: 10px;
}
</style>
