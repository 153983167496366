<template>
  <div class="Aggregateretrieval">
    <Headernav></Headernav>
    <Retrievalnav></Retrievalnav>
    <!-- <Retrievalsearch @func="getfunc"></Retrievalsearch> -->
    <div class="retrievalsearch">
      <div style="marigin-top: 40px" class="serchdiv">
        <el-input
          placeholder="请输入关键字"
          v-model="input3"
          class="input-with-select"
          style="
            width: 800px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            border: 1px solid #d9d8d8;
          "
          @keyup.enter.native="entersearch()"
        >
          <el-select
            style="
              width: 120px;
              background-color: #fff;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
            "
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="图书" value="图书"></el-option>
            <el-option label="期刊" value="期刊"></el-option>
          </el-select>
          <!-- <el-select
          style="
            width: 120px;
            background-color: #fff;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            margin-left: 10px;
          "
          v-model="select"
          slot="prepend"
          placeholder="请选择"
        >
          <el-option label="馆藏地" value="1"></el-option>
          <el-option label="期刊" value="2"></el-option>
          <el-option label="绘本" value="3"></el-option>
        </el-select> -->
          <el-button
            style="
              background-color: #fff;
              border-top-right-radius: 15px;
              border-bottom-right-radius: 15px;
              background-color: #ae703e;
              color: #fff;
              height: 42px;
            "
            slot="append"
            @click="search()"
            >立即检索</el-button
          >
        </el-input>
      </div>
      <div class="checkradio">
        <el-radio-group v-model="radio" @change="agreeChange">
          <el-radio label="0">标题</el-radio>
          <el-radio label="1">作者</el-radio>
          <el-radio label="2">出版社</el-radio>
          <el-radio label="3">ISBN</el-radio>
          <el-radio label="4">索书号</el-radio>
          <el-radio label="5">全部</el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- 主体部分 -->
    <div class="banner">
      <!-- 顶部 -->
      <p class="checkedname">
        <span v-if="author" @click="delectname(0)"> {{ author }}<em>x</em></span>
        <span v-if="publish" @click="delectname(1)">{{ publish }}<em>x</em></span>
        <span v-if="publishdate" @click="delectname(2)">{{ publishdate }}<em>x</em></span>
      </p>
      <div class="top" v-if="this.title">
        <em class="leftline"></em>
        <span
          >搜索 <em>{{ title }}</em> 的资源共有 <em>{{ total }}</em> 条结果，用时
          <em>0.006</em> 秒</span
        >
        <p class="rightdiv">
          <!-- <label>每页显示：</label>
          <el-select
            style="
              width: 120px;
              background-color: #fff;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              margin-left: 10px;
            "
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="馆藏地" value="1"></el-option>
            <el-option label="期刊" value="2"></el-option>
            <el-option label="绘本" value="3"></el-option>
          </el-select> -->
          <label>显示方式：</label>
          <el-select
            style="
              width: 120px;
              background-color: #fff;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              margin-left: 10px;
            "
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="馆藏地" value="1"></el-option>
            <el-option label="期刊" value="2"></el-option>
            <el-option label="绘本" value="3"></el-option>
          </el-select>
          <label>排序方式：</label>
          <el-select
            style="
              width: 120px;
              background-color: #fff;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              margin-left: 10px;
            "
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="馆藏地" value="1"></el-option>
            <el-option label="期刊" value="2"></el-option>
            <el-option label="绘本" value="3"></el-option>
          </el-select>
        </p>
      </div>
      <!-- 内容 -->
      <div class="content" v-if="this.title">
        <!-- 左切换栏 -->
        <div class="leftscreen">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="资源类型" name="1">
              <p v-for="(i, k) in count.doctypeList" :key="k">
                {{ i.key }}（{{ i.count }}）
              </p>
            </el-collapse-item>
            <el-collapse-item title="作者" name="2">
              <p v-for="(i, k) in count.authorList" :key="k" @click="checkauthor(i.key)">
                {{ i.key }}（{{ i.count }}）
              </p>
            </el-collapse-item>
            <el-collapse-item title="出版社" name="3">
              <p
                v-for="(i, k) in count.publishList"
                :key="k"
                @click="checkpublish(i.key)"
              >
                {{ i.key }}（{{ i.count }}）
              </p>
            </el-collapse-item>
            <el-collapse-item title="出版时间" name="4">
              <p
                v-for="(i, k) in count.publishdateList"
                :key="k"
                @click="checkpublishdate(i.key)"
              >
                {{ i.key }}（{{ i.count }}）
              </p>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="right">
          <div v-for="(item, index) in searchlists" :key="index" class="list">
            <img
              :src="item.imgUrl"
              alt=""
              @click="$router.push({ path: '/Retrievaldetails', query: { id: item.id } })"
            />
            <div class="descript">
              <p
                class="title"
                @click="
                  $router.push({ path: '/Retrievaldetails', query: { id: item.id } })
                "
              >
                {{ item.title }}
              </p>
              <p
                class="athour"
                @click="
                  $router.push({ path: '/Retrievaldetails', query: { id: item.id } })
                "
              >
                — {{ item.author }}
              </p>
              <p
                @click="
                  $router.push({ path: '/Retrievaldetails', query: { id: item.id } })
                "
              >
                索书号： {{ item.callno }}
              </p>
              <p
                @click="
                  $router.push({ path: '/Retrievaldetails', query: { id: item.id } })
                "
              >
                标准编码： {{ item.isbn }}
              </p>
              <p
                @click="
                  $router.push({ path: '/Retrievaldetails', query: { id: item.id } })
                "
              >
                出版信息： {{ item.publish }} {{ item.publishdate }}
              </p>
              <p
                @click="
                  $router.push({ path: '/Retrievaldetails', query: { id: item.id } })
                "
              >
                内容简介：{{ item.content }}
              </p>
              <span class="Collection" @click="collectadd(item)">收藏</span>
              <span class="appointment" @click="registerreserve(item.id)">预约</span>
            </div>
          </div>
          <el-pagination
            v-show="total > 1"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
import Headernav from "../components/headernav.vue";
import Footer from "../components/footer.vue";
import Retrievalnav from "../components/retrievalnav.vue";
import Leftscreen from "../components/leftscreen.vue";
import Retrievalsearch from "../components/retrievalsearch.vue";
import API from "../api/api_borrow";
export default {
  name: "Aggregateretrieval",
  data() {
    return {
      input3: this.$route.query.title,
      select: "图书",
      radio: "0",
      searchlists: "",
      total: 1,
      count: "",
      title: "",
      docType: "图书",
      filtrateType: "5",
      activeNames: ["1", "2"],
      pageSize: 8,
      pageNum: 0,
      author: "",
      publish: "",
      publishdate: "",
      currentPage1: 1,
    };
  },
  components: {
    Headernav,
    Footer,
    Retrievalnav,
    Retrievalsearch,
    // Leftscreen,
  },
  watch: {},
  methods: {
    handleChange(val) {
    
    },
    search() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        this.docType = this.select;
        this.filtrateType = this.radio;
        this.title = this.input3;
        this.getsearch();
      }
    },
    getsearch() {
      API.getelksearch({
        title: this.title,
        docType: this.docType,
        filtrateType: this.filtrateType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        author: this.author,
        publish: this.publish,
        publishdate: this.publishdate,
      }).then((res) => {
        if (res.code == 0) {
          this.searchlists = res.rows;
          this.total = res.total;
          this.count = res.count;
        }
      });
    },
    agreeChange(val) {
      this.filtrateType = val;
      this.getsearch();
    },
    getsearch2() {
      API.getelksearch({
        title: this.title,
        docType: this.docType,
        filtrateType: this.filtrateType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        author: this.author,
        publish: this.publish,
        publishdate: this.publishdate,
      }).then((res) => {
        if (res.code == 0) {
          this.searchlists = res.rows;
          this.total = res.total;
        }
      });
    },
    collectadd(item) {
      API.getcollectadd({
        title: item.title,
        author: item.author,
        publisher: item.publish,
        bookType: item.doctype,
        bookId: item.id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
          });
        } else if (res.code == 500) {
          this.$message({
            message: "请先登录",
            type: "warning",
          });
        }
      });
    },
    registerreserve(id) {
      API.getregisterreserve({
        id: id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
          });
        } else if (res.code == 500) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    checkauthor(author) {
      this.author = author;
      this.pageNum = 0;
      this.getsearch2();
    },
    checkpublish(publish) {
      this.publish = publish;
      this.pageNum = 0;
      this.getsearch2();
    },
    checkpublishdate(publishdate) {
      this.publishdate = publishdate;
      this.pageNum = 0;
      this.getsearch2();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getsearch2();
    },
    delectname(name) {
      if (name == 0) {
        this.author = "";
        this.getsearch2();
      } else if (name == 1) {
        this.publish = "";
        this.getsearch2();
      } else {
        this.publishdate = "";
        this.getsearch2();
      }
    },
  },
  created() {
    this.title = this.$route.query.title;
    this.getsearch();
  },
};
</script>
<style scope>
.Aggregateretrieval {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  
}
.Aggregateretrieval .banner {
  width: 100%;
  min-height: 400px;
  height: auto;
}
.Aggregateretrieval .banner .checkedname {
  margin: 10px auto;
  padding: 0px;
  width: 1200px;
  font-size: 12px;
  margin-top: 25px;
  cursor: pointer;
}
.Aggregateretrieval .banner .checkedname span {
  margin-left: 20px;
}
.Aggregateretrieval .banner .checkedname em {
  margin-left: 5px;
  margin-right: 10px;
}
.Aggregateretrieval .banner .top {
  width: 1200px;
  border-top: 1px solid #eeeeee;
  margin: 0 auto;
  padding-top: 5px;
  font-size: 14px;
  /* margin-top: 50px; */
  border-bottom: 1px solid #eeeeee;
}
.Aggregateretrieval .banner .top label {
  margin-left: 10px;
}
.Aggregateretrieval .banner .top em {
  font-style: normal;
  color: #ff0000;
}
.Aggregateretrieval .banner .top .leftline {
  display: inline-block;
  width: 3px;
  height: 21px;
  background-color: #ff7701;
  vertical-align: middle;
  margin-right: 10px;
}
.Aggregateretrieval .banner .top .rightdiv {
  display: inline-block;
  margin-left: 230px;
}
.Aggregateretrieval .banner .content {
  width: 1200px;
  min-height: 400px;
  height: auto;
  margin: 0 auto;
  display: flex;
}
.Aggregateretrieval .banner .content .right {
  width: 950px;
  min-height: 300px;
}
.Aggregateretrieval .banner .content .right .list {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
}
.Aggregateretrieval .banner .content .right .list img {
  width: 150px;
  height: 150px;
  margin-right: 40px;
}
.Aggregateretrieval .banner .content .right .list .descript {
  font-size: 14px;
  position: relative;
  width: 80%;
}
.Aggregateretrieval .banner .content .right .list .descript .title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ff0000;
}
.Aggregateretrieval .banner .content .right .list .descript .athour {
  color: #ae703e;
  padding-left: 50px;
  margin: 5px 0px;
}
.Aggregateretrieval .banner .content .right .list .descript p {
  margin: 8px 0px;
  font-size: 13px;
}
.Aggregateretrieval .banner .content .right .list .descript span {
  display: inline-block;
  width: 80px;
  margin: 0px 10px;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.Aggregateretrieval .banner .content .right .list .descript .Collection {
  position: absolute;
  right: 100px;
  top: 0px;
  height: 26px;
  background-color: #ae703e;
  border-radius: 5px;
  line-height: 26px;
}
.Aggregateretrieval .banner .content .right .list .descript .appointment {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 26px;
  line-height: 26px;
  background-color: #ff7701;
  border-radius: 5px;
}
.Aggregateretrieval .banner .content .right .el-pagination {
  text-align: right;
  margin-top: 50px;
  margin-bottom: 100px;
}
</style>
