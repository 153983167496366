<template>
  <div class="leftscreen">
    <div class="list">
      <h5>libraryName</h5>
      <p>图书馆（122）</p>
    </div>
    <div class="list">
      <h5>libraryName</h5>
      <p>图书馆（122）</p>
    </div>
    <div class="list">
      <h5>作者</h5>
      <p class="active">图书馆（122）</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "leftscreen",
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style scope>
.leftscreen {
  width: 240px;
  padding: 0px 5px;
  min-height: 300px;
  height: auto;
  border: 1px solid #eeeeee;
  border-bottom: none;
}
.leftscreen .list {
  border-bottom: 1px solid #eeeeee;
}
.leftscreen .list h5 {
  margin: 0px;
  font-weight: 500;
  color: #ae703e;
  height: 50px;
  line-height: 50px;
  border-bottom: 1.5px solid #ae703e;
  font-size: 16 px;
}
.leftscreen .list p {
  font-size: 14px;
}
.leftscreen .list .active {
  color: #ff0000;
}
</style>
